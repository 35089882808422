<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    :transition="
      $vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'dialog-transition'
    "
    max-width="750"
    persistent
  >
    <v-card>
      <v-card-title class="primary" style="padding-top: 10px">
        <span class="white--text">ส่งออกข้อมูล</span>
        <v-btn
          icon
          class="ml-auto white--text"
          style="margin-right: -8px"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-form
          v-model="isFormValid"
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-row>
            <v-col
              v-for="(item, index) in filterContainer"
              :key="index"
              cols="12"
              sm="6"
            >
              <v-row align="center">
                <v-col cols="4">
                  <span class="pa-0">
                    {{ item.name }}
                  </span>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-if="item.model === 'route'"
                    v-model="filterForm[item.model]"
                    :items="itemLists[item.select.items]"
                    placeholder="เลือก"
                    outlined
                    dense
                    background-color="white"
                    :item-text="item.select['item-text']"
                    clearable
                    hide-details
                    return-object
                  >
                    <template v-slot:item="{ item }">
                      {{ parseInt(item.code) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ parseInt(item.code) }}
                    </template>
                  </v-select>
                  <v-select
                    v-else-if="item.model === 'control'"
                    v-model="filterForm[item.model]"
                    :items="itemLists[item.select.items]"
                    placeholder="เลือก"
                    outlined
                    dense
                    background-color="white"
                    :item-value="item.select['item-value']"
                    :item-text="item.select['item-text']"
                    clearable
                    :no-data-text="item.select.nodata"
                    hide-details
                    :loading="isLoadingSectionPart"
                  ></v-select>
                  <v-select
                    v-else-if="item.select"
                    v-model="filterForm[item.model]"
                    :items="itemLists[item.select.items]"
                    placeholder="เลือก"
                    outlined
                    dense
                    background-color="white"
                    :item-value="item.select['item-value']"
                    :item-text="item.select['item-text']"
                    clearable
                    :no-data-text="item.select.nodata"
                    hide-details
                  ></v-select>
                  <!-- km_start and km_end -->
                  <v-row v-else-if="item.model === 'km'">
                    <v-col class="pr-0">
                      <v-text-field
                        v-model="filterForm.km_min"
                        class="mr-2 right-input"
                        placeholder="0+000"
                        :rules="kmStartRules"
                        outlined
                        dense
                        background-color="white"
                        hide-details="auto"
                        @keypress="filterkm"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center px-0">
                      <div class="mt-2">&#8211;</div>
                    </v-col>
                    <v-col class="pl-0">
                      <v-text-field
                        v-model="filterForm.km_max"
                        class="ml-2 right-input"
                        placeholder="1+000"
                        background-color="white"
                        :rules="kmEndRules"
                        outlined
                        dense
                        hide-details="auto"
                        @keypress="filterkm"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div v-else-if="item.model === 'province'">
                    <v-autocomplete
                      v-model="filterForm[item.model]"
                      :items="itemLists['v-mbmms-province-exist-only']"
                      placeholder="เลือก"
                      outlined
                      dense
                      item-text="name_th"
                      item-value="province_code"
                      hide-details
                      clearable
                    ></v-autocomplete>
                  </div>
                  <div v-else class="d-flex align-center">
                    <v-text-field
                      v-model="filterForm[item.model]"
                      background-color="white"
                      placeholder="0"
                      outlined
                      dense
                      type="number"
                      class="right-input"
                      min="0"
                      hide-details
                    >
                      <template v-slot:prepend-inner>
                        <div
                          class="d-flex align-center justify-center"
                          style="
                            width: 40px;
                            height: 40px;
                            margin: -8px 4px 0 -12px;
                            background-color: #e1e1e1;
                            border-radius: 4px 0 0 4px;
                          "
                        >
                          &#8804;
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" @click="clearFilter">
          <v-icon left>mdi-close</v-icon>
          ล้างข้อมูล
        </v-btn>
        <v-btn
          class="white--text primary ml-2"
          style="width: 140px"
          :disabled="!isFormValid"
          :href="getReport()"
          target="_blank"
          @click="doSearch()"
        >
          ดำเนินการ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dialog = newVal
      }
    },
    'filterForm.route': {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.getSectionPart(newVal.road_id)
        } else {
          delete this.filterForm.control
          this.itemLists.control = []
        }
      }
    },
    'filterForm.name_dis': {
      immediate: true,
      handler (newVal, oldVal) {
        this.getRefRoad()
      }
    },
    'filterForm.name_divi' (newVal, oldVal) {
      if (newVal) {
        this.itemLists.districtByDivision = this.itemLists[
          'v-mbmms-district-exist-only'
        ].filter((district) => district.division_code === newVal)
        const checkid = this.itemLists.districtByDivision.some(
          (id) => id.distrit_code === this.filterForm.name_dis
        )
        if (!checkid) {
          delete this.filterForm.name_dis
        }
      } else {
        delete this.filterForm.name_dis
        delete this.filterForm.route
        this.itemLists.districtByDivision = []
        this.getRefRoad()
      }
    }
  },
  data: () => ({
    dialog: true,
    filterForm: {},
    province: [],
    filterContainer: [
      {
        model: 'name_divi',
        name: 'สำนัก',
        type: 'select',
        select: {
          items: 'v-mbmms-division-exist-only',
          'item-value': 'division_code',
          'item-text': 'name'
        }
      },
      {
        model: 'name_dis',
        name: 'แขวงทางหลวง',
        type: 'select',
        select: {
          items: 'districtByDivision',
          'item-value': 'district_code',
          'item-text': 'name',
          nodata: 'โปรดเลือกสำนัก'
        }
      },
      {
        model: 'route',
        name: 'หมายเลขทางหลวง',
        type: 'select',
        select: {
          items: 'road',
          'item-value': 'road_id',
          'item-text': 'label'
        }
      },
      {
        model: 'control',
        name: 'ตอนควบคุม',
        type: 'select',
        select: {
          items: 'control',
          'item-value': 'section_part_id',
          'item-text': 'label',
          nodata: 'โปรดเลือกหมายเลขทางหลวง'
        }
      },
      {
        model: 'km',
        name: 'ช่วง กม. ที่'
      },
      {
        model: 'bridge_type',
        name: 'ชนิดโครงสร้างหลัก',
        type: 'select',
        select: {
          items: 'bridge_structure',
          'item-value': 'id',
          'item-text': 'name'
        }
      },
      {
        model: 'width',
        name: 'ความกว้างผิวทาง (ม.)'
      },
      {
        model: 'length',
        name: 'ความยาวสะพาน (ม.)'
      },
      {
        model: 'province',
        name: 'จังหวัด'
      },
      {
        model: 'sr_id',
        name: 'S.R.',
        type: 'select',
        select: {
          items: 'sr',
          'item-value': 'id',
          'item-text': 'name'
        }
      }
    ],
    itemLists: {
      road: [],
      control: [],
      bridgeType: [],
      province: []
    },
    name: null,
    isFormValid: false,
    isLoadingSectionPart: false
  }),
  mounted () {
    // this.getRef('province')
    this.getRef('bridge_structure')
    this.getRef('v-mbmms-district-exist-only')
    this.getRef('v-mbmms-division-exist-only')
    this.getRef('v-mbmms-province-exist-only')
    this.getRefRoad()
    this.getRefFilterSr()
  },
  computed: {
    kmStartRules () {
      const rules = []
      if (this.filterForm.km_min) {
        const rule = (v) =>
          (v && /^[0-9]+[+][0-9][0-9][0-9]$/.test(v)) ||
          /^[0-9]+[+][0-9][0-9][0-9]\.[0-9]+$/.test(v) ||
          'ผิดรูปแบบ'
        rules.push(rule)
      }
      return rules
    },
    kmEndRules () {
      const rules = []
      if (this.filterForm.km_max) {
        const rule = (v) =>
          (v && /^[0-9]+[+][0-9][0-9][0-9]$/.test(v)) ||
          /^[0-9]+[+][0-9][0-9][0-9]\.[0-9]+$/.test(v) ||
          'ผิดรูปแบบ'
        rules.push(rule)
      }
      return rules
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clearFilter () {
      this.filterForm = {}
    },
    doSearch () {
      if (this.$refs.form.validate()) {
        // this.$emit('close', this.filterForm)
        this.getReport()
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
      // this.filter = {}
    },
    async getRefRoad () {
      const filter = {
        district_code: this.filterForm.name_dis
      }
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-road-exist-only',
        filter
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      const checkid = this.filterForm.route
        ? roads.some((id) => id.road_id === this.filterForm.route.road_id)
        : false
      if (!checkid) {
        delete this.filterForm.route
        delete this.filterForm.control
        this.itemLists.control = []
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      this.isLoadingSectionPart = true
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `v-mbmms-section-part-exist-only?filters=road_id:${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls
      const checkSection = controls.find(
        (control) => control.section_part_id === this.filterForm.control
      )
      if (!checkSection) {
        this.filterForm.control = null
        delete this.filterForm.control
      }
      this.isLoadingSectionPart = false
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getRefFilterSr () {
      this.itemLists.sr = await this.$store.dispatch('getRefFilterSr')
    },
    filterkm (event) {
      event = event || window.event
      const expect = event.target.value.toString() + event.key.toString()
      if (
        !/^[0-9]*$/.test(expect) &&
        !/^[0-9]+[+]$/.test(expect) &&
        !/^[0-9]+[+][0-9]*$/.test(expect)
      ) {
        event.preventDefault()
      } else {
        return true
      }
    },
    getReport () {
      const params = new URLSearchParams()
      const filter = { ...this.filterForm }
      filter.sr_id = filter.sr_id ? filter.sr_id : 0
      filter.km_min = filter.km_min || 0
      filter.km_max = filter.km_max || 0
      filter.route = filter.route || 0
      filter.control = filter.control || 0
      filter.bridge_type = filter.bridge_type || 0
      filter.name_divi = filter.name_divi || 0
      filter.name_dis = filter.name_dis || 0
      filter.width = filter.width || 0
      filter.length = filter.length || 0
      filter.province = filter.province || 0
      for (const [key, value] of Object.entries(filter)) {
        if (value === 0) {
          params.append(key, 0)
        } else if (key === 'km_min' || key === 'km_max') {
          params.append(key, parseInt(value.replace('+', '')))
        } else if (key === 'route') {
          params.append(key, parseInt(value.label))
        } else if (key === 'control') {
          const control = this.itemLists.control.find(
            (section) => section.section_part_id === filter.control
          ).code
          params.append(key, control)
        } else if (key === 'sr_id') {
          const sr = value === 'N' ? 4 : value
          params.append(key, sr)
        } else if (key === 'bridge_type') {
          // params.append(key, parseInt(value))
          const bridgeType = this.itemLists.bridge_structure.find(
            (bridge) => bridge.id === value
          )
          params.append(key, bridgeType.name)
        } else if (key === 'name_divi') {
          const nameDivi = this.itemLists['v-mbmms-division-exist-only'].find(
            (divi) => divi.division_code === value
          )
          params.append(key, nameDivi.name)
        } else if (key === 'name_dis') {
          const nameDis = this.itemLists['v-mbmms-district-exist-only'].find(
            (dis) => dis.district_code === value
          )
          params.append(key, nameDis.name)
        } else if (key === 'province') {
          const nameProvince = this.itemLists[
            'v-mbmms-province-exist-only'
          ].find((province) => province.province_code === value)
          params.append(key, nameProvince.name_th)
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const link = `${
        process.env.VUE_APP_REPORT
      }/Reports/M_BMMS_reports/bridge_list.xls${
        params.toString() ? `?${params.toString()}` : ''
      }`
      return link
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
