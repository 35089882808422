var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 flex-table",attrs:{"headers":_vm.headers,"items":_vm.bridgeList,"expanded":_vm.expanded,"group-by":"section_part_id","hide-default-footer":"","items-per-page":_vm.bridgeList.length,"no-data-text":"ไม่มีข้อมูลสะพาน","loading":_vm.isloading,"fixed-header":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"pointer-hover",class:{
        'my-light-blue': item.id === _vm.selectedId
      },attrs:{"data-id":item.id}},[_c('td',{on:{"click":function($event){return _vm.viewInventory(item)}}},[_vm._v(_vm._s(_vm.getKm(item.km)))]),_c('td',{on:{"click":function($event){return _vm.viewInventory(item)}}},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.direction ? ("(" + (item.direction) + ")") : '')+" ")]),_c('td',{on:{"click":function($event){return _vm.viewInventory(item)}}},[_vm._v(_vm._s(item.structure.name))]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('v-icon',{on:{"click":function($event){return _vm.goto(item)}}},[_vm._v("mdi-map-marker-right")]),_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":("https://www.google.co.th/maps?cbll=" + (_vm.getLatLon(item.geom).lat) + "," + (_vm.getLatLon(item.geom).lon) + "&layer=c")}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-walk")])],1),_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":("https://www.google.co.th/maps/place/" + (_vm.getLatLon(item.geom).lat) + "," + (_vm.getLatLon(item.geom).lon))}},[_c('v-icon',[_vm._v("mdi-directions")])],1)],1)])])]}}:null,{key:"group.header",fn:function(ref){
            var group = ref.group;
            var toggle = ref.toggle;
            var isOpen = ref.isOpen;
return [_c('td',{staticClass:"pointer-hover",attrs:{"colspan":"3"},domProps:{"innerHTML":_vm._s(_vm.getSectionPartName(group))},on:{"click":toggle}}),_c('td',{staticClass:"text-right pointer-hover",on:{"click":toggle}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}},{key:"item.km",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getKm(item.km))+" ")]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.direction ? ("(" + (item.direction) + ")") : '')+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex mb-4"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-icon',{on:{"click":function($event){return _vm.goto(item)}}},[_vm._v("mdi-map-marker-right")]):_vm._e(),_c('a',{staticClass:"text-decoration-none d-flex align-center",attrs:{"target":"_blank","href":("https://www.google.co.th/maps?cbll=" + (_vm.getLatLon(item.geom).lat) + "," + (_vm.getLatLon(item.geom).lon) + "&layer=c")}},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"primary"}},[_vm._v("mdi-walk")])],1),_c('a',{staticClass:"text-decoration-none d-flex align-center",attrs:{"target":"_blank","href":("https://www.google.co.th/maps/place/" + (_vm.getLatLon(item.geom).lat) + "," + (_vm.getLatLon(item.geom).lon))}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-directions")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.viewInventory(item)}}},[_vm._v(" ข้อมูลสะพาน "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }