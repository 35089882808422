<template>
  <div>
    <v-dialog
      v-model="isOpenDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      :transition="
        $vuetify.breakpoint.xs
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      :max-width="width"
      persistent
    >
      <v-card>
        <div class="primary white--text">
          <v-card-title>
            เพิ่มบัญชีสะพาน
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>

        <v-card-text class="ma-0 pa-0">
          <v-form class="pa-4" ref="form">
            <v-row>
              <v-col
                v-for="(form, formindex) in formAddField"
                :key="formindex"
                :cols="form.cols"
                :sm="form.colsm"
              >
                <div class="my__v-subheader mb-1 form-text">
                  {{ form.name }}
                  <span v-if="form.isrequired" class="red--text"> * </span>
                </div>
                <v-text-field
                  v-if="form.model === 'km'"
                  v-model="addForm[form.model]"
                  class="right-input"
                  placeholder="0+000"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.required, kmRules]"
                  @keypress="filterkm"
                ></v-text-field>
                <v-text-field
                  v-else-if="form.type === 'text'"
                  v-model="addForm[form.model]"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  v-else-if="form.type === 'number'"
                  v-model="addForm[form.model]"
                  class="right-input"
                  type="number"
                  :placeholder="form.placeholder ? form.placeholder : '0'"
                  min="0"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-select
                  v-else-if="form.type === 'select'"
                  v-model="addForm[form.model]"
                  placeholder="เลือก"
                  outlined
                  dense
                  hide-details="auto"
                  :no-data-text="`ไม่มี ${form.name} ให้เลือก`"
                  :items="itemLists[form.select.name]"
                  :item-text="form.select['item-text']"
                  :item-value="form.select['item-value']"
                  :loading="sectionloading && form.model === 'section_part_id'"
                  clearable
                  :rules="[rules.required]"
                >
                </v-select>
                <v-autocomplete
                  v-else-if="form.model === 'province_code'"
                  v-model="addForm[form.model]"
                  :items="itemLists[form.autocomplete.name]"
                  placeholder="เลือก"
                  outlined
                  dense
                  hide-details="auto"
                  item-text="name_th"
                  item-value="province_code"
                  :rules="[rules.required]"
                  :no-data-text="form.nodata"
                ></v-autocomplete>
                <v-autocomplete
                  v-else-if="form.model === 'amphoe_code'"
                  v-model="addForm[form.model]"
                  :items="amphoeByProvince"
                  placeholder="เลือก"
                  outlined
                  dense
                  hide-details="auto"
                  item-text="name_th"
                  item-value="amphoe_code"
                  :rules="[rules.required]"
                  :no-data-text="form.nodata"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <div>
                  <div class="my__v-subheader form-text">โครงสร้างย่อย</div>
                  <v-checkbox
                    v-model="addForm.has_bridge_part_1"
                    class="mt-2"
                    :label="`โครงสร้างย่อยที่ 1 ${
                      addForm.ref_direction_id
                        ? addForm.ref_direction_id === '1'
                          ? '(L1)'
                          : '(R1)'
                        : '(L1/R1)'
                    }`"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                  <div
                    class="v-input mt-0 v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--is-disabled v-input--is-readonly theme--light v-input--selection-controls v-input--checkbox"
                  >
                    <div class="v-input__control">
                      <div class="v-input__slot">
                        <div class="v-input--selection-controls__input">
                          <i
                            aria-hidden="true"
                            class="v-icon notranslate mdi mdi-checkbox-marked theme--light"
                          ></i
                          ><input
                            aria-checked="true"
                            disabled="disabled"
                            id="input-128"
                            role="checkbox"
                            type="checkbox"
                            value=""
                          />
                          <div
                            class="v-input--selection-controls__ripple"
                          ></div>
                        </div>
                        <label
                          for="input-128"
                          class="v-label v-label--is-disabled theme--light"
                          style="left: 0px; right: auto; position: relative"
                          >โครงสร้างย่อยที่ 2
                          {{
                            `${
                              addForm.ref_direction_id
                                ? addForm.ref_direction_id === '1'
                                  ? '(L2)'
                                  : '(R2)'
                                : '(L2/R2)'
                            }`
                          }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <v-checkbox
                    v-model="addForm.has_bridge_part_3"
                    class="mt-0"
                    :label="`โครงสร้างย่อยที่ 3 ${
                      addForm.ref_direction_id
                        ? addForm.ref_direction_id === '1'
                          ? '(L3)'
                          : '(R3)'
                        : '(L3/R3)'
                    }`"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div>
                  <div class="my__v-subheader form-text">โครงสร้างสะพาน</div>
                  <v-checkbox
                    v-model="addForm.has_a1"
                    class="mt-2"
                    :label="
                      addForm.ref_bridge_structure_id !== '1' ? 'A1' : `ww1`
                    "
                    :true-value="1"
                    :false-value="0"
                    :disabled="addForm.ref_bridge_structure_id === '1'"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="addForm.has_a2"
                    class="mt-0"
                    :label="
                      addForm.ref_bridge_structure_id !== '1' ? 'A2' : `ww2`
                    "
                    :true-value="1"
                    :false-value="0"
                    :disabled="addForm.ref_bridge_structure_id === '1'"
                    hide-details
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" style="width: 140px" @click="clear">
            <v-icon>mdi-close</v-icon> ล้างข้อมูล
          </v-btn>
          <v-btn
            class="green white--text"
            elevation="0"
            style="width: 140px"
            :disabled="isDisabledSubmit || isUpdateLoading"
            @click="confirm()"
          >
            บันทึก
            <v-progress-circular
              v-if="isUpdateLoading"
              color="white"
              :size="20"
              indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="dialogTitle"
      :isLoading="isLoading"
      @confirm="confirmAdd()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    value: Boolean,
    title: String,
    width: {
      type: Number,
      default: () => {
        return 760
      }
    },
    isDisabledSubmit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isUpdateLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    isOpenDialog: false,
    isConfirmDialog: false,
    dialogTitle: 'ยืนยันการเพิ่มบัญชีสะพาน ?',
    formAddField: [
      {
        name: 'ชื่อ',
        model: 'bridge_name',
        cols: 12,
        colsm: 6,
        type: 'text',
        isrequired: true
      },
      {
        name: 'กม. ที่',
        model: 'km',
        cols: 6,
        colsm: 3,
        type: 'text',
        isrequired: true
      },
      {
        name: 'ทิศทางจารจร',
        model: 'ref_direction_id',
        cols: 6,
        colsm: 3,
        type: 'select',
        select: {
          name: 'direction',
          'item-text': 'name',
          'item-value': 'id'
        },
        isrequired: true
      },
      {
        name: 'หมายเลขทางหลวง',
        model: 'road_id',
        cols: 12,
        colsm: 6,
        type: 'select',
        select: {
          name: 'road',
          'item-text': 'label',
          'item-value': 'road_id'
        },
        isrequired: true
      },
      {
        name: 'ตอนควบคุม',
        model: 'section_part_id',
        cols: 12,
        colsm: 6,
        type: 'select',
        select: {
          name: 'control',
          'item-text': 'label',
          'item-value': 'section_part_id'
        },
        isrequired: true
      },
      {
        name: 'จังหวัด',
        model: 'province_code',
        cols: 12,
        colsm: 6,
        type: 'autocomplete',
        autocomplete: {
          name: 'province',
          'item-text': 'name_th',
          'item-value': 'province_code'
        },
        isrequired: true,
        nodata: 'ไม่มี จังหวัด ให้เลือก'
      },
      {
        name: 'อำเภอ/เขต',
        model: 'amphoe_code',
        cols: 12,
        colsm: 6,
        type: 'autocomplete',
        autocomplete: {
          name: 'amphoe',
          'item-text': 'name_th',
          'item-value': 'amphoe_code'
        },
        isrequired: true,
        nodata: 'ไม่มี อำเภอ/เขต ให้เลือก'
      },
      {
        name: 'ละติจูด',
        model: 'lat',
        cols: 12,
        colsm: 6,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ลองจิจูด',
        model: 'lon',
        cols: 12,
        colsm: 6,
        type: 'number',
        isrequired: true
      },
      {
        name: 'เจ้าของสะพาน',
        model: 'ref_bridge_owner_id',
        cols: 12,
        colsm: 6,
        type: 'select',
        select: {
          name: 'bridge-owner',
          'item-text': 'name',
          'item-value': 'id'
        },
        isrequired: true
      },
      {
        name: 'ประเภทสะพาน',
        model: 'ref_bridge_category_id',
        cols: 12,
        colsm: 6,
        type: 'select',
        select: {
          name: 'bridge_category',
          'item-text': 'name',
          'item-value': 'id'
        },
        isrequired: true
      },
      {
        name: 'ปีที่สร้างสะพาน',
        model: 'year',
        cols: 12,
        colsm: 6,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ศูนย์สร้างและบูรณะสะพาน',
        model: 'ref_bridge_center_id',
        cols: 12,
        colsm: 6,
        type: 'select',
        select: {
          name: 'bridge_center',
          'item-text': 'name',
          'item-value': 'id'
        },
        isrequired: true
      },
      {
        name: 'ชนิดโครงสร้างหลัก',
        model: 'ref_bridge_structure_id',
        cols: 12,
        colsm: 6,
        type: 'select',
        select: {
          name: 'bridge_structure',
          'item-text': 'name',
          'item-value': 'id'
        },
        isrequired: true
      },
      {
        name: 'ประเภทสิ่งที่ข้าม',
        model: 'ref_bridge_cross_id',
        cols: 12,
        colsm: 6,
        type: 'select',
        select: {
          name: 'bridge_cross',
          'item-text': 'name',
          'item-value': 'id'
        },
        isrequired: true
      }
    ],
    itemLists: {},
    addForm: {
      // has_bridge_part_1: 1,
      // has_bridge_part_3: 1,
      // province_code: '10',
      // amphoe_code: '01',
      // bridge_name: 'ทดสอบ 1 ',
      // km: '1234',
      // ref_direction_id: '1',
      // road_id: '4227',
      // section_part_id: '11566',
      // lat: '13.625032',
      // lon: '101.522155',
      // ref_bridge_owner_id: '1',
      // year: '2565'
      has_a1: 1,
      has_a2: 1
    },
    sectionloading: false,
    amphoeByProvince: [],
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    },
    isLoading: false
  }),
  watch: {
    async value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) this.clear()
    },
    'addForm.road_id' (newVal, oldVal) {
      console.log(newVal)
      if (newVal) {
        delete this.addForm.section_part_id
        this.getSectionPart(newVal)
      } else {
        delete this.addForm.section_part_id
        this.itemLists.control = []
      }
    },
    'addForm.province_code' (newVal, oldVal) {
      if (newVal) {
        this.setAmphoeList()
        if (oldVal !== undefined) {
          this.addForm.amphoe_code = null
        }
      } else {
        this.amphoeByProvince = []
        this.addForm.amphoe_code = null
      }
    },
    'addForm.ref_bridge_structure_id' (newVal, oldVal) {
      if (newVal === '1') {
        this.addForm.has_a1 = 1
        this.addForm.has_a2 = 1
      }
    }
  },
  computed: {
    bridgeName () {
      return this.$store.state.appbarText.header
    },
    kmRules () {
      const rule = (v) =>
        (v && /^[0-9]+[+][0-9][0-9][0-9]$/.test(v)) ||
        /^[0-9]+[+][0-9][0-9][0-9]\.[0-9]+$/.test(v) ||
        'ผิดรูปแบบ'
      return rule
    }
  },
  async mounted () {
    this.getRefRoad()
    this.getRef('province')
    this.getRef('amphoe')
    this.getRef('bridge_category')
    this.getRef('bridge_center')
    this.getRef('bridge_structure')
    this.getRef('bridge_cross')
    for (const item of this.formAddField) {
      if (
        item.type === 'select' &&
        item.model !== 'road_id' &&
        item.model !== 'section_part_id'
      ) {
        await this.getRef(item.select.name)
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    closeConfirm () {
      this.isConfirmDialog = false
    },

    async clear () {
      if (this.$refs.form) {
        await this.$refs.form.reset()
        this.addForm = {
          has_bridge_part_1: 0,
          has_bridge_part_3: 0,
          has_a1: 1,
          has_a2: 1
        }
      }
    },
    confirm () {
      if (this.$refs.form.validate()) {
        this.isConfirmDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    },
    async confirmAdd () {
      this.isLoading = true
      const addForm = { ...this.addForm }
      addForm.km = addForm.km.replace('+', '')
      const data = await this.$store.dispatch('setBridge', addForm)
      if (data) {
        this.$emit('confirm', data)
      }
      this.isLoading = false
      this.isConfirmDialog = false
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getRefRoad () {
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'road'
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      const checkid = roads.some((id) => id.road_id === this.addForm.road_id)
      if (!checkid) {
        this.itemLists.control = []
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      this.sectionloading = true
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `section_part/${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls
      this.sectionloading = false
    },
    setAmphoeList () {
      if (this.itemLists.amphoe) {
        const amphoeByProvince = this.itemLists.amphoe.filter((amphoe) => {
          return amphoe.province_code === this.addForm.province_code
        })
        this.amphoeByProvince = amphoeByProvince
      } else this.amphoeByProvince = []
    },
    filterkm (event) {
      event = event || window.event
      const expect = event.target.value.toString() + event.key.toString()
      if (
        !/^[0-9]*$/.test(expect) &&
        !/^[0-9]+[+]$/.test(expect) &&
        !/^[0-9]+[+][0-9]*$/.test(expect)
      ) {
        event.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
