<template>
  <v-data-table
    class="elevation-2 flex-table"
    :headers="headers"
    :items="bridgeList"
    :expanded.sync="expanded"
    group-by="section_part_id"
    hide-default-footer
    :items-per-page="bridgeList.length"
    no-data-text="ไม่มีข้อมูลสะพาน"
    :loading="isloading"
    fixed-header
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:item="{ item }">
      <tr
        class="pointer-hover"
        :class="{
          'my-light-blue': item.id === selectedId
        }"
        :data-id="item.id"
      >
        <td @click="viewInventory(item)">{{ getKm(item.km) }}</td>
        <td @click="viewInventory(item)">
          {{ item.name }} {{ item.direction ? `(${item.direction})` : '' }}
        </td>
        <td @click="viewInventory(item)">{{ item.structure.name }}</td>
        <td>
          <div class="d-flex">
            <v-icon @click="goto(item)">mdi-map-marker-right</v-icon>
            <a
              class="text-decoration-none"
              target="_blank"
              :href="`https://www.google.co.th/maps?cbll=${
                getLatLon(item.geom).lat
              },${getLatLon(item.geom).lon}&layer=c`"
            >
              <v-icon class="mx-2">mdi-walk</v-icon>
            </a>
            <a
              class="text-decoration-none"
              target="_blank"
              :href="`https://www.google.co.th/maps/place/${
                getLatLon(item.geom).lat
              },${getLatLon(item.geom).lon}`"
            >
              <v-icon>mdi-directions</v-icon>
            </a>
          </div>
        </td>
      </tr>
    </template>
    <template v-slot:group.header="{ group, toggle, isOpen }">
      <td
        class="pointer-hover"
        colspan="3"
        v-html="getSectionPartName(group)"
        @click="toggle"
      ></td>
      <td class="text-right pointer-hover" @click="toggle">
        <v-btn small icon :ref="group" :data-open="isOpen">
          <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </td>
    </template>
    <template v-slot:item.km="{ item }">
      {{ getKm(item.km) }}
    </template>
    <template v-slot:item.name="{ item }">
      {{ item.name }} {{ item.direction ? `(${item.direction})` : '' }}
    </template>
    <template v-slot:item.action="{ item }">
      <div class="d-flex mb-4">
        <v-icon v-if="$vuetify.breakpoint.smAndUp" @click="goto(item)"
          >mdi-map-marker-right</v-icon
        >
        <a
          class="text-decoration-none d-flex align-center"
          target="_blank"
          :href="`https://www.google.co.th/maps?cbll=${
            getLatLon(item.geom).lat
          },${getLatLon(item.geom).lon}&layer=c`"
        >
          <v-icon color="primary" class="mx-2">mdi-walk</v-icon>
        </a>
        <a
          class="text-decoration-none d-flex align-center"
          target="_blank"
          :href="`https://www.google.co.th/maps/place/${
            getLatLon(item.geom).lat
          },${getLatLon(item.geom).lon}`"
        >
          <v-icon color="primary">mdi-directions</v-icon>
        </a>
        <v-btn
          class="ml-2"
          color="primary"
          outlined
          @click="viewInventory(item)"
        >
          ข้อมูลสะพาน <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  props: {
    bridgeData: Array,
    selectedId: Number,
    isloading: Boolean
  },
  computed: {
    bridgeList () {
      const bridgeList = []
      for (const section of this.bridgeData) {
        for (const bridge of section.items) {
          bridgeList.push({
            ...bridge,
            section_part_id: section.section_part_id
          })
        }
      }
      return bridgeList
    }
  },
  data: () => ({
    headers: [
      {
        text: 'กม.',
        align: 'start',
        sortable: false,
        class: 'primary white--text rounded-tl ',
        value: 'km'
      },
      {
        text: 'ชื่อสะพาน',
        value: 'name',
        sortable: false,
        class: 'primary white--text '
      },
      {
        text: 'ชนิดโครงสร้างหลัก',
        value: 'structure.name',
        class: 'primary white--text ',
        sortable: false
      },
      {
        text: '',
        value: 'action',
        class: 'primary white--text rounded-tr ',
        sortable: false
      }
    ],
    expanded: [],
    getKm: utils.getKm
  }),
  watch: {
    bridgeData (newVal, oldVal) {
      setTimeout(() => {
        this.closeAll()
      }, 500)
    }
  },
  methods: {
    goto (bridge) {
      this.$emit('goto', bridge)
    },
    getLatLon (geom) {
      const geomstr = String(geom)
        .replace('POINT', '')
        .replace('(', '')
        .replace(')', '')
      const lonlat = geomstr.split(' ')
      return { lon: lonlat[0], lat: lonlat[1] }
    },
    closeAll () {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click()
        }
      })
    },
    async goHighlight () {
      await this.closeAll()
      // open section part
      const currentSection = this.bridgeList.find(
        (bridge) => bridge.id === this.selectedId
      ).section_part_id
      await Object.keys(this.$refs).forEach((k) => {
        if (
          this.$refs[k] &&
          !this.$refs[k].$attrs['data-open'] &&
          currentSection === parseInt(k)
        ) {
          this.$refs[k].$el.click()
        }
      })

      // scroll to bridge id
      setTimeout(() => {
        this.scrollOnClick()
      }, 500)
    },
    getSectionPartName (group) {
      const section = this.bridgeData.find(
        (section) => section.section_part_id === group
      )
      return `<div class="header-section-expansion">
        <b>ทางหลวงหมายเลข ${parseInt(section.code_road)} ตอน ${
        section.code_section
      } </b> ${section.name} (${section.items.length} สะพาน)
      </div>`
    },
    scrollOnClick () {
      const el = document.querySelector(`[data-id="${this.selectedId}"]`)
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center' // Move to center of screen
        })
      }
    },
    viewInventory (item) {
      console.log(window.getSelection())
      this.$router.push({
        path: '/inventory',
        query: { id: item.id }
      })
    }
  }
}
</script>

<style>
.background-bridge {
  background-color: #f3f3f3;
}

.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.flex-table > div {
  width: 100%;
  background: red;
}
.flex-table > .v-data-table__wrapper {
  width: 100%;
  background: transparent;
}

.header-section-expansion {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 600px) {
  .bridgeTable__my-sticky,
  ::v-deep .bridgeTable__my-sticky .v-data-table__wrapper {
    overflow: unset;
  }
  .flex-table.theme--light.v-data-table .v-row-group__header,
  .flex-table.theme--light.v-data-table .v-row-group__summary {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
}

@media screen and (max-width: 600px) {
  .bridgeTable__my-sticky,
  ::v-deep .bridgeTable__my-sticky .v-data-table__wrapper {
    overflow: unset;
  }
  .flex-table.theme--light.v-data-table .v-row-group__header,
  .flex-table.theme--light.v-data-table .v-row-group__summary {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  .flex-table tbody {
    padding-bottom: 60px;
  }
}
</style>
